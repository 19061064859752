.navy {
  color: #2C5E7B;
}

.dark-navy {
  color: #02304d;
}

.aqua {
  color: #02A1AC;
}

.teal {
  color: #1D828D;
}

.light-teal {
  color: #26C5D0;
}

.purple {
  color: #50247E;
}

.light-purple {
  color: #A88CB8;
}

.off-white-purple {
  color: #DFE5F5;
}

.orange {
  color: #D47404;
}

.dark-orange {
  color: #C25700;
}

.light-orange {
  color: #F5980A;
}

.text-gray {
  color: #49494A;
}

.text-dark-gray {
  color: #58595B;
}

.teal-shadow {
  color: #186C75;
}

.white-shadow {
  color: #E3EEEE;
}

.navy-shadow {
  color: #225471;
}

.orange-shadow {
  color: #946000;
}

.light-blue {
  color: #DFEEEF;
}

.brown {
  color: #998358;
}

.tan {
  color: #F5EBD9;
}

.off-white {
  color: #EAEFF2;
}

.white {
  color: #FFFFFF;
}

.black {
  color: #000000;
}

.red {
  color: #FF0000;
}

.modal-bg {
  color: rgba(0, 0, 0, 0.5);
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300 800;
  font-stretch: 75% 100%;
  src: url("/assets/fonts/open-sans-latin-regular-variable.woff2") format("woff2");
}
.sitemap-content-container {
  padding: 64px 0;
  background: #2C5E7B;
}
.sitemap-content-container .sitemap-list-outer-container {
  width: 100%;
}
.sitemap-content-container .sitemap-list-outer-container ul {
  margin-bottom: 16px;
}
.sitemap-content-container .sitemap-list-outer-container ul:last-of-type {
  margin-bottom: 0;
}
.sitemap-content-container .sitemap-list-outer-container ul li {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 75;
  color: #2C5E7B;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
}
.sitemap-content-container .sitemap-list-outer-container ul li ul {
  margin-top: 12px;
}

@media only screen and (min-width: 992px) {
  .sitemap-content-container {
    padding: 48px 0 72px 0;
  }
}